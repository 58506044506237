import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const Terms= () => (
  <Layout>
    <Seo title="Terms of Service" />
    {/* Content */}

    <div className="container max-w-screen-xl pt-16 sm:pt-32 pb-16 sm:pb-64 sm:text-2xl">
    <h1 className="_h1">Terms of Service</h1>
    <h3 className="_p"><strong>1. </strong><strong>GENERAL</strong></h3>
    <p className="_p">1.1. These terms of use (hereinafter the "<strong>Terms</strong>") govern the access and use of LeverID demo version.</p>
    <p className="_p">
        1.2. The Terms form an inseparable part of the agreement which is deemed to be concluded between you and us once you have accepted them. By accepting these Terms, you acknowledge that you have read, understood, and agree to be bound
        by the Terms.
    </p>
    <h3 className="_p"><strong>2. </strong><strong>DEFINITIONS</strong></h3>
    <p className="_p">2.1. For the purposes of these Terms, the following terms shall have the following meanings:</p>
    <p className="_p">(a) <strong>“Agreement”&nbsp;</strong>– an agreement for the use of the Application concluded between you and us in accordance with the Terms;</p>
    <p className="_p">(b) <strong>“Application”&nbsp;</strong>– a demo version of LeverID mobile application available on iOS and Android platforms;</p>
    <p className="_p">
        (c) <strong>“Intellectual Property”</strong>&nbsp;– all existing and future author's rights (copyrights), including any neighbouring and related rights, and industrial property rights in and related to the Application, including all
        applications for renewal or extension of those rights, and other similar or equivalent rights and means of protection;
    </p>
    <p className="_p">(d) <strong>“Party”</strong>&nbsp;or&nbsp;<strong>“Parties”</strong>&nbsp;– a common term for you and us;</p>
    <p className="_p">
        (e) <strong>“We”&nbsp;</strong>or&nbsp;<strong>“us”</strong>&nbsp;– Levercode OÜ, a limited liability company established and existing under the laws of Republic of Estonia, registry code 12334812, address Ranna tee 9, Maramaa küla,
        Tartu vald, 60517 Tartu maakond, Estonia.
    </p>
    <h3 className="_p"><strong>3. </strong><strong>THE APPLICATION</strong></h3>
    <p className="_p">
        3.1. We offer you the Application free of charge and only as a demo version in order to demonstrate you its functionality, main characteristics, and capabilities. The Application does not include the full functionality which will be
        found in the full version once we have completed the development. We reserve the right to change the functionality, design, and content of the Application when and where necessary. We may also terminate offering the Application for
        any reason we choose.
    </p>
    <p className="_p">
        3.2. We use test (or “dummy”) data to the maximum possible extent in order to adhere to principles of data minimisation and privacy by design. Despite that, we still need to process your certain personal data in order to demonstrate
        you the Application. You can read more about the data processing from our privacy policy.
    </p>
    <h3 className="_p"><strong>4. </strong><strong>INTELLECTUAL PROPERTY RIGHTS</strong></h3>
    <p className="_p">4.1. The Application, any parts, and elements thereof (including, but not limited to databases, content and documentation) are and may be protected under the Intellectual Property rights that belong to us or third parties.</p>
    <p className="_p">
        4.2. During the validity of the Agreement, we allow you to use the functionality of the Application in compliance with the Agreement and for the purposes stated herein. We shall not give you any other licenses or rights and you
        shall not obtain any Intellectual Property rights to the Application or any content made accessible through the Application.
    </p>
    <p className="_p">
        4.3. You may not change, copy, duplicate, distribute, process, translate, transmit, add to other databases, or make available to the public the Application, or use the Intellectual Property concerning the Application in any other
        way than provided herein, without our prior written consent. Furthermore, you have no right to issue sublicenses for the use of the Application or create new intellectual property objects based on it.
    </p>
    <h3 className="_p"><strong>5. </strong><strong>LIABILITY</strong></h3>
    <p className="_p">
        5.1. We shall be only liable for direct and proprietary damages caused to you either intentionally or due to gross negligence. We shall not under any circumstances be liable for any loss of profit or other indirect damages incurred
        by you under the Agreement.&nbsp;
    </p>
    <p className="_p">
        5.2. The Application is provided on “as is” and “as available” basis, meaning that we do not guarantee its 24/7 and error-free operation.&nbsp;<em>Inter alia</em>, we shall not be liable to you for the damage and other consequences
        that have arisen due to:
    </p>
    <p className="_p">(a) permanent or temporary interruption, discontinuance, suspension or other type of unavailability of the Application;</p>
    <p className="_p">
        (b) errors, damages, or settings in your device that are unsuited for the use of the Application (for the details of supported and suitable devices please contact us at&nbsp;
        <a href="mailto:info@levercode.com">info@levercode.com</a>);
    </p>
    <p className="_p">(c) changes in legal acts and in their interpretations, their impacts on you and implementing those changes in the Application, unless it is obligatory to us under the law or a court decision made regarding us;</p>
    <p className="_p">(d) any modifications in these Terms.</p>
    <h3 className="_p"><strong>6. </strong><strong>TERM AND TERMINATION</strong></h3>
    <p className="_p">6.1. The Agreement shall be effective between you and us as of the moment when you accepted these Terms.</p>
    <p className="_p">6.2. The Agreement is entered into for an indefinite term and may be terminated by either Party anytime without any reason.</p>
    <p className="_p">
        6.3. The Agreement ends automatically and without notice once we remove the Application from our test environments which are meant and may be accessed for public testing, and/or once we terminate our users’ access to these test
        environments.
    </p>
    <h3 className="_p"><strong>7. </strong><strong>GOVERNING LAW AND DISPUTE RESOLUTION</strong></h3>
    <p className="_p">
        7.1. The Agreement shall be governed and construed in accordance with laws of Republic of Estonia. Upon non-compliance of the object of the Agreement with terms and conditions of the Agreement, you can rely on the legal remedies
        laid down in § 101 (1) of the Law of Obligations Act.
    </p>
    <p className="_p">7.2. The Parties undertake to use their best efforts to resolve any dispute, misunderstanding or claim arising out or, or in connection with the Agreement, or its breach, termination, or invalidity by amicable negotiations.</p>
    <p className="_p">
        7.3. If the Parties fail to settle disputes through amicable negotiations, such dispute, misunderstanding, or claim arising out of, or in connection with the Agreement, or its breach, termination or invalidity, shall be resolved in
        Harju County Court, Republic of Estonia.
    </p>
    <p className="_p">
        7.4. If you are a consumer, you may submit complaints regarding this Agreement with the Consumer Disputes Committee (Endla 10A, 10142 Tallinn, Estonia, email:&nbsp;<a href="mailto:avaldus@komisjon.ee">avaldus@komisjon.ee</a>,&nbsp;
        <a href="https://komisjon.ee/">https://komisjon.ee</a>) or by completing an application form on the Electronic Consumer Dispute Resolution Platform at&nbsp;<a href="http://ec.europa.eu/odr/">http://ec.europa.eu/odr/</a>.&nbsp;
    </p>
    <h3 className="_p"><strong>8. </strong><strong>MISCELLANEOUS</strong></h3>
    <p className="_p">
        8.1. We have the right to unilaterally change the Terms at any time, by publishing the new wording of the Terms, together with the changes made in the Application. You should review any changes to the Terms, and if such changes are
        not acceptable to you, immediately terminate your use of the Application.
    </p>
    <p className="_p">
        8.2. Illegality, invalidity, or unenforceability of any provision or provisions of these Terms does not affect the validity, legality, or enforceability of the remaining provisions of these Terms. If any provision proves to be
        illegal, invalid, or unenforceable, the Parties will in good faith use all reasonable endeavours to replace that provision with a new, lawful, valid, and enforceable provision closest to the commercial substance of original
        provision.
    </p>
    <p className="_p">8.3. Failure of either Party to exercise or enforce any provision or any of its rights under these Terms shall not be deemed a waiver of future enforcement of that or any other provision or right.</p>

    </div>
  </Layout>
);

export default Terms;
